<template>
	<el-form :model="adminForm" ref="adminForm" label-width="100px">
		<div v-for="(item, index) in adminDataForm" :key="index">
			<el-form-item :label="item.label" v-if="item.isInput != false" :rules="item.rules" :prop="item.attr">
				<uploaderimgfile v-if="item.type == 'img'" v-model="adminForm[item.attr]" :imageUrl="imageUrl" @handleAvatarSuccess="handleAvatarSuccess" />
				<el-select v-else-if="item.type == 'enum'" v-model="adminForm[item.attr]" :placeholder="item.placeholder" filterable>
					<el-option v-for="ite in item.dic" :key="ite.value" :label="ite.title" :value="ite.value"> </el-option>
				</el-select>
				<el-select v-else-if="item.type == 'multiple'" v-model="adminForm[item.attr]" :placeholder="item.placeholder" multiple filterable>
					<el-option v-for="ite in item.dic" :key="ite.value" :label="ite.title" :value="ite.value"> </el-option>
				</el-select>
				<el-date-picker v-else-if="item.type == 'date'" type="date" :placeholder="item.placeholder" v-model="adminForm[item.attr]" :format="item.format" :value-format="item.valueformat"></el-date-picker>
				<el-input v-else-if="item.type == 'textarea'" type="textarea" v-model="adminForm[item.attr]" :placeholder="item.placeholder"></el-input>
				<el-switch v-else-if="item.type == 'switch'" v-model="adminForm[item.attr]"></el-switch>
				<el-input v-else v-model="adminForm[item.attr]" :placeholder="item.placeholder"></el-input>
			</el-form-item>
		</div>
		<el-form-item>
			<el-button type="primary" @click="submitForm('adminForm')">保存</el-button>
			<el-button @click="resetForm('adminForm')">重置</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
export default {
	name: "saveAdmin",
	props: {
		adminId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			imageUrl: {
				Id: 190016502280262,
				Url: "",
			},
			adminForm: {
				Id: 0,
				AdminAvatar: 0,
				AdminName: "",
				AdminIDCard: "",
				AdminPhoneAccount: "",
				AdminBirthday: "",
				Sex: 1,
				RoleIds: "",
				RemarkInfo: "",
			},
			adminDataForm: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
				},
				{
					label: "头像",
					attr: "AdminAvatar",
					rules: [{ required: false, message: "请上传员工头像" }],
					type: "img",
				},
				{
					label: "员工姓名",
					attr: "AdminName",
					rules: [
						{ required: true, message: "请输入员工姓名" },
						{ max: 10, message: "长度不大于10!" },
					],
					type: "text",
				},
				{
					label: "员工角色",
					attr: "RoleIds",
					placeholder: "请选择员工角色",
					rules: [
						{
							required: true,
							message: "请选择员工角色",
							trigger: "change",
						},
					],
					type: "multiple",
					dic: this.GetRoleAlls(),
				},
				{
					label: "出生日期",
					attr: "AdminBirthday",
					placeholder: "出生日期",
					format: "YYYY 年 MM 月 DD 日",
					valueformat: "YYYY/MM/DD",
					rules: [
						{
							type: "date",
							required: true,
							message: "请选择出生日期",
							trigger: "change",
						},
					],
					type: "date",
				},
				{
					label: "性别",
					attr: "Sex",
					placeholder: "请选择员工性别",
					type: "enum",
					dic: [
						{ value: 1, title: "男" },
						{ value: 2, title: "女" },
						{ value: 3, title: "未知" },
					],
				},
				{
					label: "身份证号",
					attr: "AdminIDCard",
					placeholder: "请输入身份证号",
					rules: [
						{
							required: false,
							trigger: "blur",
							message: "请输入身份证号!",
						},
						{
							pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
							message: "格式验证失败,请检查身份证号是否正确!",
							trigger: "blur",
						},
					],
				},
				{
					label: "手机号",
					attr: "AdminPhoneAccount",
					placeholder: "请输入手机号",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入手机号!",
						},
						{
							pattern: /^1\d{10}$/,
							message: "格式验证失败,请检查手机号是否正确!",
							trigger: "blur",
						},
					],
				},
				{
					label: "备注",
					attr: "RemarkInfo",
					placeholder: "请输入备注（长度不大于100个字符）",
					rules: [
						{
							min: 0,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
					type: "textarea",
				},
			],
		};
	},
	methods: {
		initEditAdminModel() {
			var that = this;
			if (that.$props.adminId != 0) {
				that.$API.Admin.QueryById.get(that.$props.adminId).then((res) => {
					if (res) {
						that.imageUrl.Id = res.AdminAvatar;
						that.imageUrl.Url = res.AdminAvatarUrl;
						that.adminForm.Id = res.Id;
						that.adminForm.AdminAvatar = res.AdminAvatar;
						that.adminForm.AdminName = res.AdminName;
						that.adminForm.AdminIDCard = res.AdminIDCard;
						that.adminForm.AdminPhoneAccount = res.AdminPhoneAccount;
						that.adminForm.AdminBirthday = res.AdminBirthday;
						that.adminForm.Sex = res.Sex;
						that.adminForm.RemarkInfo = res.RemarkInfo;
						that.adminForm.RoleIds = res.AdminRoles.map((it) => it.RoleId);
					}
				});
			}
		},
		GetRoleAlls() {
			var data = [];
			this.$API.Role.GetRoleAlls.post({}).then((res) => {
				if (res) {
					res.forEach((it) => {
						data.push({ value: it.Id, title: it.RoleName });
					});
				}
				this.initEditAdminModel();
			});
			return data;
		},
		handleAvatarSuccess(res) {
			if (res.Success && res.Code == 200) {
				this.imageUrl = res.Data;
				this.adminForm.AdminAvatar = this.imageUrl.Id;
			} else {
				console.log(res.Message);
			}
		},
		submitForm(formName) {
			var that = this;
			if (!that.imageUrl.Id) {
				that.$message.warning("请上传头像!");
				return;
			}
			that.adminForm.AdminAvatar = that.imageUrl.Id;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					that.$API.Admin.AddOrUpdate.post(that.adminForm).then((res) => {
						if (res.Success) {
							that.$message.success(res.Message);
							that.$emit("operationSuccess");
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		resetForm(formName) {
			this.imageUrl = {};
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style></style>
